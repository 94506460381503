import { NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';
import { useGetExampleQuery } from '../../slices/user/apis/userApi';

const LogIn: NextPage = () => {
  // const count = useSelector((state: RootState) => state.user.count);
  // // const token = useSelector((state: RootState) => state.server.token);
  // const dispatch = useDispatch();
  const {
    // data,
    // error,
    // isLoading,
    refetch,
  } = useGetExampleQuery(null);
  const getExample = () => {
    refetch();
    fetch('/api/example').then((res) => res.json()).then(() => {
      // console.log(result);
    });
  };
  const {
    t,
    // lang,
  } = useTranslation('common');

  return (
    <div>
      <h1>Login Page</h1>
      <button
        type="button"
        onClick={getExample}
      >
        {t('button')}
      </button>
    </div>
  );
};

export default LogIn;
